import { cn } from "@/lib/utils";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { isRouteErrorResponse, useRouteError } from "react-router";

export function ErrorMessageBoundary({
  link = true,
  url = "/",
  className,
}: {
  link?: boolean;
  url?: string;
  className?: string;
}) {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  let errorMessage = error instanceof Error ? error.message : undefined;
  const stack = error instanceof Error ? error.stack : undefined;
  let errorTitle = "Algo salio mal";
  if (isRouteErrorResponse(error)) {
    if (error.status >= 400) {
      errorTitle = `${error.status} ${error.statusText}`;
    } else {
      errorTitle = error.statusText;
    }
    if (error.data?.message) {
      errorMessage = error.data.message;
    }
  }

  return (
    <div className={cn("flex flex-col items-center justify-center", className)}>
      {errorTitle && <h2 className="mb-4 text-3xl font-bold">{errorTitle}</h2>}
      {errorMessage && <p className="mb-4 text-lg">{errorMessage}</p>}
      {stack && <pre className="text-xs">{stack}</pre>}
      {link && (
        <Link
          to={url}
          className="text-blue-500 underline"
          reloadDocument={true}
        >
          Regresar a inicio
        </Link>
      )}
    </div>
  );
}
